import "core-js/modules/es.number.to-fixed.js";
import { computed, defineComponent } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { numberWithCommas } from '@/utils/numberWithCommas';
import TooltipIcon from '@/components/share/TooltipIcon.vue';
import { useStore } from 'vuex';
import { LEVEL_MAPPING } from '@/utils/levelMapping';
export default defineComponent({
  name: 'LevelStep',
  components: {
    SvgIcon: SvgIcon,
    TooltipIcon: TooltipIcon
  },
  props: ['level', 'isUpperContent'],
  setup: function setup(props) {
    var store = useStore();
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var currentLevel = computed(function () {
      return LEVEL_MAPPING[props.level];
    });
    var nextLevel = props.level !== 4 ? computed(function () {
      return LEVEL_MAPPING[props.level + 1];
    }) : undefined;
    var percentage = computed(function () {
      return +(levelInfo.value.cumulative_amount / (levelInfo.value.cumulative_amount + levelInfo.value.amount_to_upgrade)).toFixed(2) * 100;
    });
    return {
      currentLevel: currentLevel,
      nextLevel: nextLevel,
      numberWithCommas: numberWithCommas,
      levelInfo: levelInfo,
      percentage: percentage
    };
  }
});