import { computed, defineComponent, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import LevelStep from '@/components/personal/LevelStep.vue';
import { numberWithCommas } from '@/utils/numberWithCommas';
import { LEVEL_MAPPING } from '@/utils/levelMapping';
import Slider from '@/components/share/Sliders.vue';
import { memToken } from '../api/index';
import { useAuthStore } from '@/hook/useAuthStore';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'Membership',
  components: {
    HeaderComponent: Header,
    FooterComponent: Footer,
    LevelStep: LevelStep,
    Slider: Slider,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var store = useStore();
    var isMobile = inject('isMobile');
    var onLoginModelToggle = inject('onLoginModelToggle');
    var userInfo = useAuthStore().userInfo;
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var levelId = computed(function () {
      return store.state.user.levelInfo.level_id - 1;
    });
    var isLoggedIn = useAuthStore().isLoggedIn;

    var getCardImg = function getCardImg(levelId) {
      return require("@/assets/images/member-card-vip".concat(levelId, ".png"));
    };

    onMounted(function () {
      if (memToken) store.dispatch('user/userLevel');
    });
    return {
      LEVEL_MAPPING: LEVEL_MAPPING,
      numberWithCommas: numberWithCommas,
      isMobile: isMobile,
      levelId: levelId,
      levelInfo: levelInfo,
      userInfo: userInfo,
      getCardImg: getCardImg,
      isLoggedIn: isLoggedIn,
      onLoginModelToggle: onLoginModelToggle
    };
  }
});