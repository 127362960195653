export const LEVEL_MAPPING = [
  {
    name: '普卡',
    levelId: 1,
    hint: '免費入會',
    price: 0,
    rebate: '',
    recommendPoint: 100,
    registerPoint: 100,
    cardImg: 'member-card-vip1.png'
  },
  {
    name: '銀卡',
    levelId: 2,
    hint: (price: number) => `消費 ${price} 元`,
    price: 8000,
    rebate: '5%',
    recommendPoint: 100,
    registerPoint: '',
    cardImg: 'member-card-vip2.png'
  },
  {
    name: '金卡',
    levelId: 3,
    hint: (price: number) => `消費 ${price} 元`,
    price: 15000,
    rebate: '8%',
    recommendPoint: 100,
    registerPoint: '',
    cardImg: 'member-card-vip3.png'
  },
  {
    name: '白金',
    levelId: 4,
    hint: (price: number) => `消費 ${price} 元`,
    price: 30000,
    rebate: '10%',
    recommendPoint: 100,
    registerPoint: '',
    cardImg: 'member-card-vip4.png'
  },
  {
    name: '鑽石',
    levelId: 5,
    hint: (price: number) => `消費 ${price} 元`,
    price: 60000,
    rebate: '15%',
    recommendPoint: 100,
    registerPoint: '',
    cardImg: 'member-card-vip5.png'
  }
]
